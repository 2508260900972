
import { StepperComponent } from "@/assets/ts/components";
import Step1 from "@/components/wizard/solicitudDatos/Step1.vue";
import Step2 from "@/components/wizard/solicitudDatos/Step2.vue";
import Step3 from "@/components/wizard/solicitudDatos/Step3.vue";
import Step4 from "@/components/wizard/solicitudDatos/Step4.vue";
import { municipios } from "@/core/data/municipiosProvinciasComarcas";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import SolicitudExternaModule from "@/store/modules/SolicitudExternaModule";
import { ElMessage } from "element-plus";
import Swal from "sweetalert2";
import { useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";
import { mapGetters, useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface IStep1 {
  entidadResponsable: string;
  formulaGestionContrato: string;
  importe: number;
  localizacionAcciones: string;
  municipiosIds: string[];
}

interface IStep2 {
  tipoServicios: string[];
  ampliacionHorarios: boolean;
  vacacionesEscolares: boolean;
  nPlazas: number;
  nSolicitudes: number;

  nPersonasMenores3: number;
  nPersonas3a6: number;
  nPersonas7a9: number;
  nPersonas10a12: number;
  nPersonas13a16: number;
  nPersonasEdadNC: number;

  nPersonasMujer: number;
  nPersonasVaron: number;
  nPersonasOtro: number;
  nPersonasNC: number;

  nFamiliasNumerosa: number;
  nFamiliasMonoparental: number;
  nFamiliasRefugiada: number;
  nFamiliasVictima: number;
  nFamiliasOtrasCargas: number;
  nFamiliasOtra: number;
  nFamiliasNC: number;
}

interface IStep3 {
  nPlazasE: number;
  nSolicitudesE: number;

  nPersonasMenores30: number;
  nPersonas30a45: number;
  nPersonasMas45: number;
  nPersonasEdadNCE: number;
  nPersonasMujerE: number;
  nPersonasVaronE: number;
  nPersonasOtroE: number;
  nPersonasNCE: number;

  nMujeresLargaDuracion: number;
  nVaronesLargaDuracion: number;
  nPersonasVictima: number;
  nMujeresRefugiadas: number;
  nVaronesRefugiados: number;

  nPersonasIndefinido: number;
  nPersonasTemporal: number;
  nPersonasFijoDiscontinuo: number;
  nPersonasFormativo: number;
  nPersonasTipoContratoNC: number;

  nPersonasJornadaCompleta: number;
  nPersonasJornadaParcial: number;
  nPersonasJornadaNC: number;
}

interface IStep4 {
  gradoAlcanceObjetivos: string;
  dificultadesEncontradas: string;
  propuestasFuturas: string;
}

interface Solicitud extends IStep1, IStep2, IStep3, IStep4 {}

export default defineComponent({
  name: "kt-horizontal-wizard",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },

  props: {
    formularioId: String,
    comarcaId: String,
    solicitudId: String,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { t, te } = useI18n();
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const module = getModule(SolicitudExternaModule, store);
    const envioLoad = ref(false);
    const isTest = props.solicitudId === "test" && props.comarcaId === "test";
    const isSent = ref(false);

    const formData = ref<Solicitud>({
      entidadResponsable: Step1.respuestaEntidad?.value,
      formulaGestionContrato: "",
      importe: Step1.importe?.value,
      localizacionAcciones: "",
      municipiosIds: [],

      tipoServicios: [],
      ampliacionHorarios: false,
      vacacionesEscolares: false,
      nPlazas: Step2.nPlazas?.value,
      nSolicitudes: Step2.nSolicitudes?.value,
      nPersonasMenores3: Step2.nPersonasMenores3?.value,
      nPersonas3a6: Step2.nPersonas3a6?.value,
      nPersonas7a9: Step2.nPersonas7a9?.value,
      nPersonas10a12: Step2.nPersonas10a12?.value,
      nPersonas13a16: Step2.nPersonas13a16?.value,
      nPersonasEdadNC: Step2.nPersonasEdadNC?.value,
      nPersonasMujer: Step2.nPersonasMujer?.value,
      nPersonasVaron: Step2.nPersonasVaron?.value,
      nPersonasOtro: Step2.nPersonasOtro?.value,
      nPersonasNC: Step2.nPersonasNC?.value,
      nFamiliasNumerosa: Step2.nFamiliasNumerosa?.value,
      nFamiliasMonoparental: Step2.nFamiliasMonoparental?.value,
      nFamiliasRefugiada: Step2.nFamiliasRefugiada?.value,
      nFamiliasVictima: Step2.nFamiliasVictima?.value,
      nFamiliasOtrasCargas: Step2.nFamiliasOtrasCargas?.value,
      nFamiliasOtra: Step2.nFamiliasOtra?.value,
      nFamiliasNC: Step2.nFamiliasNC?.value,

      nPlazasE: Step3.nPlazasE?.value,
      nSolicitudesE: Step3.nSolicitudesE?.value,
      nPersonasMenores30: Step3.nPersonasMenores30?.value,
      nPersonas30a45: Step3.nPersonas30a45?.value,
      nPersonasMas45: Step3.nPersonasMas45?.value,
      nPersonasEdadNCE: Step3.nPersonasEdadNCE?.value,
      nPersonasMujerE: Step3.nPersonasMujerE?.value,
      nPersonasVaronE: Step3.nPersonasVaronE?.value,
      nPersonasOtroE: Step3.nPersonasOtroE?.value,
      nPersonasNCE: Step3.nPersonasNCE?.value,
      nMujeresLargaDuracion: Step3.nMujeresLargaDuracion?.value,
      nVaronesLargaDuracion: Step3.nVaronesLargaDuracion?.value,
      nPersonasVictima: Step3.nPersonasVictima?.value,
      nMujeresRefugiadas: Step3.nMujeresRefugiadas?.value,
      nVaronesRefugiados: Step3.nVaronesRefugiados?.value,
      nPersonasIndefinido: Step3.nPersonasIndefinido?.value,
      nPersonasTemporal: Step3.nPersonasTemporal?.value,
      nPersonasFijoDiscontinuo: Step3.nPersonasFijoDiscontinuo?.value,
      nPersonasFormativo: Step3.nPersonasFormativo?.value,
      nPersonasTipoContratoNC: Step3.nPersonasTipoContratoNC?.value,
      nPersonasJornadaCompleta: Step3.nPersonasJornadaCompleta?.value,
      nPersonasJornadaParcial: Step3.nPersonasJornadaParcial?.value,
      nPersonasJornadaNC: Step3.nPersonasJornadaNC?.value,

      gradoAlcanceObjetivos: "",
      dificultadesEncontradas: "",
      propuestasFuturas: "",
    });

    onBeforeMount(() => {
      if (!props.solicitudId || !props.comarcaId) {
        return router.replace("/404");
      }

      if (props.solicitudId === "test" && props.comarcaId === "test") {
        return;
      }

      module
        .fetchSolicitudes({
          solicitudId: props.solicitudId,
          comarcaId: props.comarcaId,
        })
        .catch((err) => {
          if (err?.error?.statusCode === 422) {
            return router.replace("/solicitud-respondida");
          }
          router.replace("/404");
        });
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );

      setCurrentPageBreadcrumbs("Horizontal", ["Pages", "Wizards"]);
    });

    const i18n = (text) => (te(text) ? t(text) : text);

    const createAccountSchema = [
      Yup.object({
        entidadResponsable: Yup.string()
          .notRequired()
          .label(i18n("solicitud.step1.entidades")),
        formulaGestionContrato: Yup.string()
          .required("El campo formula de gestión del contrato es obligatorio")
          .label(i18n("solicitud.step1.formula")),
        importe: Yup.number()
          .required("El campo importe es obligatorio")
          .label(i18n("solicitud.step1.importe"))
          .typeError(i18n("solicitud.error.numero")),
        localizacionAcciones: Yup.string()
          .required("El campo localización de las acciones es obligatorio")
          .label(i18n("solicitud.step1.localizacion")),
         municipiosIds: Yup.array()
          .required()
          .min(1, "Debe seleccionar al menos uno")
          .label(t("solicitud.step1.municipios")),
      }),
      Yup.object({
        tipoServicios: Yup.array()
          .of(
            Yup.string().oneOf(["AMPLIACION_HORARIOS", "VACACIONES_ESCOLARES"])
          )
          .min(1, "Debe seleccionar al menos uno")
          .required("Debe seleccionar al menos uno")
          .label(i18n("solicitud.step2.tipoDeServicio")),
        nPlazas: Yup.number()
          .label(i18n("solictud.step2.plazas"))
          .typeError(i18n("solicitud.error.numero")),
        nSolicitudes: Yup.number()
          .label(i18n("solictud.step2.solicitudes"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasMenores3: Yup.number()
          .label(i18n("soliciutd.step2.nPersonasMenores3"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonas3a6: Yup.number()
          .label(i18n("solicitud.step2.nPersonas3a6"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonas7a9: Yup.number()
          .label(i18n("solicitud.step2.nPersonas7a9"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonas10a12: Yup.number()
          .label(i18n("solicitud.step2.nPersonas10a12"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonas13a16: Yup.number()
          .label(i18n("solicitud.step2.nPersonas13a16"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasEdadNC: Yup.number()
          .label(i18n("solicitud.sept2.nPersonasEdadNC"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasMujer: Yup.number()
          .label(i18n("solictud.step2.nPersonasMujer"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasVaron: Yup.number()
          .label(i18n("solicitud.step2.nPersonasVaron"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasOtro: Yup.number()
          .label(i18n("solicitud.step2.nPersonasOtro"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasNC: Yup.number().label(i18n("solictud.step2.nPersonasNC")),
        nFamiliasNumerosa: Yup.number()
          .label(i18n("solictud.step2.nFamiliasNumerosa"))
          .typeError(i18n("solicitud.error.numero")),
        nFamiliasMonoparental: Yup.number()
          .label(i18n("solictud.step2.nFamiliasMonoparental"))
          .typeError(i18n("solicitud.error.numero")),
        nFamiliasRefugiada: Yup.number()
          .label(i18n("solicitud.step2.nFamiliasRefugiada"))
          .typeError(i18n("solicitud.error.numero")),
        nFamiliasVictima: Yup.number()
          .label(i18n("solictud.step2.nFamiliasVictima"))
          .typeError(i18n("solicitud.error.numero")),
        nFamiliasOtrasCargas: Yup.number()
          .label(i18n("soliciutd.step2.nFamiliasOtrasCargas"))
          .typeError(i18n("solicitud.error.numero")),
        nFamiliasOtra: Yup.number()
          .label(i18n("solictud.step2.nFamiliasOtra"))
          .typeError(i18n("solicitud.error.numero")),
        nFamiliasNC: Yup.number()
          .label(i18n("solicitud.step2.nFamiliasNC"))
          .typeError(i18n("solicitud.error.numero")),
      }),
      Yup.object({
        nPlazasE: Yup.number()
          .label(i18n("solicitud.step3.nPlazas"))
          .typeError(i18n("solicitud.error.numero")),
        nSolicitudesE: Yup.number()
          .label(i18n("soliciutd.step3.nSolicitudes"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasMenores30: Yup.number()
          .label(i18n("solictud.step3.nPersonasMenores30"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonas30a45: Yup.number()
          .label(i18n("solicitud.step3.nPersonas30a45"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasMas45: Yup.number()
          .label(i18n("solicitud.step3.nPersonasMas45"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasEdadNCE: Yup.number()
          .label(i18n("soliciutd.step3.nPersonasEdadNC"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasMujerE: Yup.number()
          .label(i18n("soliciutd.step3.nPersonasMujer"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasVaronE: Yup.number()
          .label(i18n("solicitud.step3.nPersonasVaron"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasOtroE: Yup.number()
          .label(i18n("solicitud.step3.nPersonasOtro"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasNCE: Yup.number()
          .label(i18n("soliciutd.step3.nPersonasNC"))
          .typeError(i18n("solicitud.error.numero")),
        nMujeresLargaDuracion: Yup.number()
          .label(i18n("soliciutd.step3.nMujeresLargaDuracion"))
          .typeError(i18n("solicitud.error.numero")),
        nVaronesLargaDuracion: Yup.number()
          .label(i18n("soliciutd.step3.nVaronesLargaDuracion"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasVictima: Yup.number()
          .label(i18n("solicitud.step3.nPersonasVictima"))
          .typeError(i18n("solicitud.error.numero")),
        nMujeresRefugiadas: Yup.number()
          .label(i18n("solicitud.step3.nMujeresRefugiadas"))
          .typeError(i18n("solicitud.error.numero")),
        nVaronesRefugiados: Yup.number()
          .label(i18n("solicitud.step3.nVaronesRefugiados"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasIndefinido: Yup.number()
          .label(i18n("solicitud.step3.nPersonasIndefinido"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasTemporal: Yup.number()
          .label(i18n("solicitud.step3.nPersonasTemporal"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasFijoDiscontinuo: Yup.number()
          .label(i18n("solicitud.step3.nPersonasFijoDiscontinuo"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasFormativo: Yup.number()
          .label(i18n("solicitud.step3.nPersonasFormativo"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasTipoContratoNC: Yup.number()
          .label(i18n("solicitud.step3.nPersonasTipoContratoNC"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasJornadaCompleta: Yup.number()
          .label(i18n("solicitud.step3.nPersonasJornadaCompleta"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasJornadaParcial: Yup.number()
          .label(i18n("solicitud.step3.nPersonasJornadaParcial"))
          .typeError(i18n("solicitud.error.numero")),
        nPersonasJornadaNC: Yup.number()
          .label(i18n("solicitud.step3.nPersonasJornadaNC"))
          .typeError(i18n("solicitud.error.numero")),
      }),
      Yup.object({
        gradoAlcanceObjetivos: Yup.string().label(
          i18n("solictud.step4.gradoAlcanceObjetivos")
        ),
        dificultadesEncontradas: Yup.string().label(
          i18n("solicitud.step4.dificultadesEncontradas")
        ),
        propuestasFuturas: Yup.string().label(
          i18n("solicitud.step4.propuestasFuturas")
        ),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<
      IStep1 | IStep2 | IStep3 | IStep4
    >({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const paso2 = () => {
      if (currentStepIndex.value === 1) {
        window.scrollTo(0, 0);
      }
    };

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      if (
        totalSteps.value != null &&
        currentStepIndex.value + 1 >= totalSteps.value
      ) {
        return formSubmit(formData.value);
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
      window.scrollTo(0, 0);
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const goStep = (step: number) => {
      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goto(step);

      currentStepIndex.value = step - 1;
    };

    const formSubmit = async (formData) => {
      if (!props.solicitudId || !props.comarcaId) {
        return router.replace("/404");
      }

      if (isTest) {
        return;
      }

      envioLoad.value = true;

      try {
        const municipiosSeleccionados = municipios.filter(municipio =>
        formData.municipiosIds.includes(municipio.municipioId)
        );

        store
          .dispatch(
            "SolicitudExternaModule/" + Actions.POST_SOLICITUD_EXTERNA,
            {
              datosSolicitud: {
                formularioId: props.formularioId as string,
                solicitudId: props.solicitudId as string,
                comarcaId: props.comarcaId as string,
                entidadResponsable: module.entidadResponsable,
                formulaGestionContrato: formData.formulaGestionContrato,
                importe: formData.importe,
                localizacionAcciones: formData.localizacionAcciones,
                municipios: municipiosSeleccionados,
                bolsaCuidados: {
                  tipoBolsaCuidados: formData.tipoServicios,
                  nPlazas: formData.nPlazas || 0,
                  nSolicitudes: formData.nSolicitudes || 0,

                  nPersonasMenores3: formData.nPersonasMenores3 || 0,
                  nPersonas3a6: formData.nPersonas3a6 || 0,
                  nPersonas7a9: formData.nPersonas7a9 || 0,
                  nPersonas10a12: formData.nPersonas10a12 || 0,
                  nPersonas13a16: formData.nPersonas13a16 || 0,
                  nPersonasEdadNC: formData.nPersonasEdadNC || 0,

                  nPersonasMujer: formData.nPersonasMujer || 0,
                  nPersonasVaron: formData.nPersonasVaron || 0,
                  nPersonasOtro: formData.nPersonasOtro || 0,
                  nPersonasNC: formData.nPersonasNC || 0,

                  nFamiliasNumerosa: formData.nFamiliasNumerosa || 0,
                  nFamiliasMonoparental: formData.nFamiliasMonoparental || 0,
                  nFamiliasRefugiada: formData.nFamiliasRefugiada || 0,
                  nFamiliasVictima: formData.nFamiliasVictima || 0,
                  nFamiliasOtrasCargas: formData.nFamiliasOtrasCargas || 0,
                  nFamiliasOtra: formData.nFamiliasOtra || 0,
                  nFamiliasNC: formData.nFamiliasNC || 0,
                },
                creacionEmpleo: {
                  nPlazas: formData.nPlazasE || 0,
                  nSolicitudes: formData.nSolicitudesE || 0,

                  nPersonasMenores30: formData.nPersonasMenores30 || 0,
                  nPersonas30a45: formData.nPersonas30a45 || 0,
                  nPersonasMas45: formData.nPersonasMas45 || 0,
                  nPersonasEdadNC: formData.nPersonasEdadNCE || 0,
                  nPersonasMujer: formData.nPersonasMujerE || 0,
                  nPersonasVaron: formData.nPersonasVaronE || 0,
                  nPersonasOtro: formData.nPersonasOtroE || 0,
                  nPersonasNC: formData.nPersonasNCE || 0,

                  nMujeresLargaDuracion: formData.nMujeresLargaDuracion || 0,
                  nVaronesLargaDuracion: formData.nVaronesLargaDuracion || 0,
                  nPersonasVictima: formData.nPersonasVictima || 0,
                  nMujeresRefugiadas: formData.nMujeresRefugiadas || 0,
                  nVaronesRefugiados: formData.nVaronesRefugiados || 0,

                  nPersonasIndefinido: formData.nPersonasIndefinido || 0,
                  nPersonasTemporal: formData.nPersonasTemporal || 0,
                  nPersonasFijoDiscontinuo:
                    formData.nPersonasFijoDiscontinuo || 0,
                  nPersonasFormativo: formData.nPersonasFormativo || 0,
                  nPersonasTipoContratoNC:
                    formData.nPersonasTipoContratoNC || 0,

                  nPersonasJornadaCompleta:
                    formData.nPersonasJornadaCompleta || 0,
                  nPersonasJornadaParcial:
                    formData.nPersonasJornadaParcial || 0,
                  nPersonasJornadaNC: formData.nPersonasJornadaNC || 0,
                },
                gradoAlcanceObjetivos: formData.gradoAlcanceObjetivos || "",
                dificultadesEncontradas: formData.dificultadesEncontradas || "",
                propuestasFuturas: formData.propuestasFuturas || "",
              },
            }
          )
          .then((codigo) => {
            Swal.fire({
              html: t("formularioSolicitudExternaExito", { codigo }),
              icon: "success",
              showConfirmButton: false,
            }).then(() => {
              isSent.value = true;
            });
          })
          .catch((err) => {
            console.error("Err", err);
            ElMessage.error(t("formularioEnviadoError"));
          });
      } catch (err) {
        console.error(err);
        ElMessage.error(t("formularioEnviadoError"));
      } finally {
        envioLoad.value = false;
      }
    };

    return {
      horizontalWizardRef,
      previousStep,
      handleStep,
      totalSteps,
      currentStepIndex,
      goStep,
      paso2,
      isTest,
      isSent,
    };
  },
  computed: {
    ...mapGetters(["isPendingForm"]),
  },
});
