import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04b6ef62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 mx-15 mb-10" }
const _hoisted_2 = { class: "pb-10 pb-lg-15" }
const _hoisted_3 = { class: "fw-bold d-flex align-items-center text-gray-900 colorTitle" }
const _hoisted_4 = { class: "form-floating mb-5" }
const _hoisted_5 = { class: "form-label mb-3" }
const _hoisted_6 = { class: "form-floating mb-5" }
const _hoisted_7 = { class: "form-label mb-3 required" }
const _hoisted_8 = { class: "form-floating mb-5" }
const _hoisted_9 = { class: "form-label mb-3 required" }
const _hoisted_10 = { class: "form-floating mb-5" }
const _hoisted_11 = { class: "form-label mb-3 required" }
const _hoisted_12 = { class: "form mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_CurrencyInput = _resolveComponent("CurrencyInput")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("solicitud.step1.titulo")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("input", {
        name: "entidadResponsable",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.respuestaEntidad) = $event)),
        type: "text",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " ",
        disabled: ""
      }, null, 512), [
        [_vModelText, _ctx.respuestaEntidad]
      ]),
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("solicitud.step1.entidades")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "entidadResponsable",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _withDirectives(_createElementVNode("input", {
        name: "formulaGestionContrato",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formulaGestionContrato) = $event)),
        type: "text",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.formulaGestionContrato]
      ]),
      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("solicitud.step1.formula")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "formulaGestionContrato",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_CurrencyInput, {
        modelValue: _ctx.importe,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.importe) = $event)),
        modelModifiers: { lazy: true },
        options: {
          currency: 'EUR',
          locale: 'es-ES',
          currencyDisplay: 'symbol',
          precision: 2,
          hideCurrencySymbolOnFocus: true,
          hideGroupingSeparatorOnFocus: true,
          hideNegligibleDecimalDigitsOnFocus: true,
          autoDecimalDigits: false,
          useGrouping: true,
          accountingSign: false,
        },
        type: "text",
        name: "importe",
        class: "form-control form-control-lg form-control-solid",
        placeholder: ""
      }, null, 8, ["modelValue"]),
      _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("solicitud.step1.importe")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "importe",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_10, [
      _withDirectives(_createElementVNode("input", {
        name: "localizacionAcciones",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localizacionAcciones) = $event)),
        type: "text",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.localizacionAcciones]
      ]),
      _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("solicitud.step1.localizacion")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "localizacionAcciones",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_el_select, {
        modelValue: _ctx.municipiosIds,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.municipiosIds) = $event)),
        name: "municipiosIds",
        class: "form-select form-select-solid form-el-select ajusteSelector",
        filterable: "",
        placeholder: _ctx.i18n('solicitud.step1.municipio'),
        "suffix-icon": "",
        multiple: "",
        size: "large"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_option, {
            disabled: "",
            value: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.i18n("solicitud.step1.municipio")), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.municipios, (municipio) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: municipio.municipioId,
              label: municipio.municipioNombre,
              value: municipio.municipioId
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(municipio.municipioNombre), 1)
              ]),
              _: 2
            }, 1032, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "placeholder"]),
      _createVNode(_component_ErrorMessage, {
        name: "municipiosIds",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ])
  ]))
}