
import { ErrorMessage, useField } from "vee-validate";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";

//import Combobox from "@/components/widgets/Combobox.vue";

export default defineComponent({
  name: "step-3",
  components: {
    ErrorMessage,
    //Combobox,
  },

  setup() {
    const { t, te } = useI18n();

    const i18n = (text) => (te(text) ? t(text) : text);

    const { value: nPlazasE } = useField("nPlazasE");
    const { value: nSolicitudesE } = useField("nSolicitudesE");

    const { value: nPersonasMenores30 } = useField("nPersonasMenores30");
    const { value: nPersonas30a45 } = useField("nPersonas30a45");
    const { value: nPersonasMas45 } = useField("nPersonasMas45");
    const { value: nPersonasEdadNCE } = useField("nPersonasEdadNCE");

    const { value: nPersonasMujerE } = useField("nPersonasMujerE");
    const { value: nPersonasVaronE } = useField("nPersonasVaronE");
    const { value: nPersonasOtroE } = useField("nPersonasOtroE");
    const { value: nPersonasNCE } = useField("nPersonasNCE");

    const { value: nMujeresLargaDuracion } = useField("nMujeresLargaDuracion");
    const { value: nVaronesLargaDuracion } = useField("nVaronesLargaDuracion");
    const { value: nPersonasVictima } = useField("nPersonasVictima");
    const { value: nMujeresRefugiadas } = useField("nMujeresRefugiadas");
    const { value: nVaronesRefugiados } = useField("nVaronesRefugiados");

    const { value: nPersonasIndefinido } = useField("nPersonasIndefinido");
    const { value: nPersonasTemporal } = useField("nPersonasTemporal");
    const { value: nPersonasFijoDiscontinuo } = useField(
      "nPersonasFijoDiscontinuo"
    );
    const { value: nPersonasFormativo } = useField("nPersonasFormativo");
    const { value: nPersonasTipoContratoNC } = useField(
      "nPersonasTipoContratoNC"
    );

    const { value: nPersonasJornadaCompleta } = useField(
      "nPersonasJornadaCompleta"
    );
    const { value: nPersonasJornadaParcial } = useField(
      "nPersonasJornadaParcial"
    );
    const { value: nPersonasJornadaNC } = useField("nPersonasJornadaNC");

    return {
      i18n,
      nPlazasE,
      nSolicitudesE,
      nPersonasMenores30,
      nPersonas30a45,
      nPersonasMas45,
      nPersonasEdadNCE,
      nPersonasMujerE,
      nPersonasVaronE,
      nPersonasOtroE,
      nPersonasNCE,
      nMujeresLargaDuracion,
      nVaronesLargaDuracion,
      nPersonasVictima,
      nMujeresRefugiadas,
      nVaronesRefugiados,
      nPersonasIndefinido,
      nPersonasTemporal,
      nPersonasFijoDiscontinuo,
      nPersonasFormativo,
      nPersonasTipoContratoNC,
      nPersonasJornadaCompleta,
      nPersonasJornadaParcial,
      nPersonasJornadaNC,
    };
  },
});
