
import { ErrorMessage, useField } from "vee-validate";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";

//import Combobox from "@/components/widgets/Combobox.vue";

export default defineComponent({
  name: "step-4",
  components: {
    ErrorMessage,
    //Combobox,
  },

  setup() {
    const { t, te } = useI18n();

    const i18n = (text) => (te(text) ? t(text) : text);

    const { value: gradoAlcanceObjetivos } = useField("gradoAlcanceObjetivos");
    const { value: dificultadesEncontradas } = useField(
      "dificultadesEncontradas"
    );
    const { value: propuestasFuturas } = useField("propuestasFuturas");

    return {
      i18n,
      gradoAlcanceObjetivos,
      dificultadesEncontradas,
      propuestasFuturas,
    };
  },
});
