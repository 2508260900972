
import CurrencyInput from "@/components/widgets/CurrencyInput.vue";
import { municipios } from "@/core/data/municipiosProvinciasComarcas";
import SolicitudExternaModule from "@/store/modules/SolicitudExternaModule";
import { ErrorMessage, useField } from "vee-validate";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "step-1",
  components: {
    ErrorMessage,
    CurrencyInput,
  },

  setup() {
    const { t, te } = useI18n();
    const i18n = (text) => (te(text) ? t(text) : text);
    const store = useStore();
    const module = getModule(SolicitudExternaModule, store);
    let municipiosId = ref<number[]>([]) as { value: number[] };

    const respuestaEntidad = computed(() => {
      return module.entidadResponsable;
    });

    const { value: entidadResponsable } = useField("entidadResponsable");
    const { value: formulaGestionContrato } = useField(
      "formulaGestionContrato"
    );
    const { value: importe } = useField("importe");
    const { value: localizacionAcciones } = useField("localizacionAcciones");
    const { value: municipiosIds } = useField("municipiosIds");

    return {
      i18n,
      entidadResponsable,
      formulaGestionContrato,
      importe,
      localizacionAcciones,
      municipiosIds,
      municipiosId,
      municipios,
      respuestaEntidad,
    };
  },
});
