
import { ErrorMessage, useField } from "vee-validate";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";

//import Combobox from "@/components/widgets/Combobox.vue";

export default defineComponent({
  name: "step-2",
  components: {
    ErrorMessage,
    //Combobox,
  },

  setup() {
    const { t, te } = useI18n();

    const i18n = (text) => (te(text) ? t(text) : text);

    const { value: AMPLIACION_HORARIOS } = useField("AMPLIACION_HORARIOS");
    const { value: VACACIONES_ESCOLARES } = useField("VACACIONES_ESCOLARES");
    const { value: tipoServicios } = useField("tipoServicios");
    const { value: nPlazas } = useField("nPlazas");
    const { value: nSolicitudes } = useField("nSolicitudes");

    const { value: nPersonasMenores3 } = useField("nPersonasMenores3");
    const { value: nPersonas3a6 } = useField("nPersonas3a6");
    const { value: nPersonas7a9 } = useField("nPersonas7a9");
    const { value: nPersonas10a12 } = useField("nPersonas10a12");
    const { value: nPersonas13a16 } = useField("nPersonas13a16");
    const { value: nPersonasEdadNC } = useField("nPersonasEdadNC");

    const { value: nPersonasMujer } = useField("nPersonasMujer");
    const { value: nPersonasVaron } = useField("nPersonasVaron");
    const { value: nPersonasOtro } = useField("nPersonasOtro");
    const { value: nPersonasNC } = useField("nPersonasNC");

    const { value: nFamiliasNumerosa } = useField("nFamiliasNumerosa");
    const { value: nFamiliasMonoparental } = useField("nFamiliasMonoparental");
    const { value: nFamiliasRefugiada } = useField("nFamiliasRefugiada");
    const { value: nFamiliasVictima } = useField("nFamiliasVictima");
    const { value: nFamiliasOtrasCargas } = useField("nFamiliasOtrasCargas");
    const { value: nFamiliasOtra } = useField("nFamiliasOtra");
    const { value: nFamiliasNC } = useField("nFamiliasNC");

    return {
      i18n,
      tipoServicios,
      AMPLIACION_HORARIOS,
      VACACIONES_ESCOLARES,
      nPlazas,
      nSolicitudes,

      nPersonasMenores3,
      nPersonas3a6,
      nPersonas7a9,
      nPersonas10a12,
      nPersonas13a16,
      nPersonasEdadNC,

      nPersonasMujer,
      nPersonasVaron,
      nPersonasOtro,
      nPersonasNC,

      nFamiliasNumerosa,
      nFamiliasMonoparental,
      nFamiliasRefugiada,
      nFamiliasVictima,
      nFamiliasOtrasCargas,
      nFamiliasOtra,
      nFamiliasNC,
    };
  },
});
